<template>
  <b-card>

    <b-card-title class="font-weight-bolder">
      Demo Page - Secondary
    </b-card-title>

    <b-row>
      <b-col cols="6">
        <div
          v-for="item in raw"
          :class="{'mt-05': true, 'ml-2': item.parentID}"
        >{{ item.name }}</div>
      </b-col>
      <b-col cols="6">
        <div
          v-for="item in fixed"
          :class="{'mt-05': true, 'ml-2': item.parentID}"
        >{{ item.name }}</div>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, onUnmounted } from '@vue/composition-api'
import { watchWithFilter, debounceFilter, useCssVar } from '@vueuse/core'
import common from '@/common'
import ButtonWithDropdown from '@/components/ButtonWithDropdown'

export default {
  components: {
    ButtonWithDropdown
  },

  setup() {

    return {}
  },
  data() {
    return {
      raw: [
        {
          'moduleID': 10,
          'name': '院舍',
          'parentID': null
        },
        {
          'moduleID': 35,
          'name': '職員',
          'parentID': null
        },
        {
          'moduleID': 41,
          'name': '會員',
          'parentID': null
        },
        {
          'moduleID': 47,
          'name': '組別',
          'parentID': null
        },
        {
          'moduleID': 53,
          'name': '職位',
          'parentID': null
        },
        {
          'moduleID': 66,
          'name': '離床裝置',
          'parentID': null
        },
        {
          'moduleID': 67,
          'name': '離床裝置信息總覽',
          'parentID': 66
        },
        {
          'moduleID': 70,
          'name': '離床裝置信息強制設定',
          'parentID': 66
        },
        {
          'moduleID': 81,
          'name': '批量上傳',
          'parentID': 66
        },
        {
          'moduleID': 83,
          'name': '緊急鍵',
          'parentID': null
        },
        {
          'moduleID': 84,
          'name': '緊急鍵記錄',
          'parentID': 83
        },
        {
          'moduleID': 98,
          'name': '防遊走',
          'parentID': null
        },
        {
          'moduleID': 99,
          'name': '遊走記錄',
          'parentID': 98
        },
        {
          'moduleID': 87,
          'name': '緊急鍵報告',
          'parentID': 83
        },
        {
          'moduleID': 102,
          'name': '遊走報告​',
          'parentID': 98
        },
        {
          'moduleID': 104,
          'name': '設備批量上傳​',
          'parentID': 98
        },
        {
          'moduleID': 90,
          'name': 'NFC標簽管理',
          'parentID': 83
        },
        {
          'moduleID': 105,
          'name': 'RFID標簽管理​',
          'parentID': 98
        },
        {
          'moduleID': 91,
          'name': '巡邏記錄',
          'parentID': 83
        },
        {
          'moduleID': 106,
          'name': 'RFID批量上傳​',
          'parentID': 98
        },
        {
          'moduleID': 92,
          'name': '巡邏報告',
          'parentID': 83
        },
        {
          'moduleID': 72,
          'name': '通知中心',
          'parentID': null
        }],
      fixed: []
    }
  },
  methods: {
    sortModules(modules) {
      const temp = []
      for (const [_key, module] of modules.entries()){
        if (module.parentID === null) {
          temp.push(module)
          for (const [_subKey, subModule] of modules.entries()) {
            if (subModule.parentID === module.moduleID) {
              temp.push(subModule)
            }
          }
        }
      }
      return temp
    }
  },
  mounted() {
    this.fixed = this.sortModules(this.raw)
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
