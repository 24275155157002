<template>
  <b-button-group>
    <b-dropdown
      right split dropdown
      :size="size" variant="primary"
      :text="options[index].text"
      @click="$emit('click', options[index].tag)"
      ref="dropdown"
    >
      <b-dropdown-item
        v-for="(option, i) in options"
        @click="handleClickOption(i)"
      >
        {{ option.text }}
      </b-dropdown-item>
    </b-dropdown>
  </b-button-group>
</template>

<script>
export default {
  props: {
    size: { type: String, default: 'md' },
    options: {
      type: Array,
      default: () => [{
        text: 'Action-1',
        tag: 'tag-1'
      }]
    }
  },
  data() {
    return {
      index: 0
    }
  },
  methods: {
    handleClickOption(i) {
      this.index = i
      this.updateButtonStatus()
    },
    updateButtonStatus() {
      const i = this.index
      const buttonElm = this.$refs.dropdown.$refs.button
      buttonElm.toggleAttribute('disabled', this.options[i].disabled === true)
    }
  },
  mounted() {
    this.updateButtonStatus()
  }
}
</script>
